import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../../components/Layout'
import SEO from '../../../components/seo'
import { LazyLoadImage } from 'react-lazy-load-image-component'
// IMAGES
import custom1 from '../../../assets/images/custom-gate1.png'
import custom2 from '../../../assets/images/custom-gate2.png'
import custom3 from '../../../assets/images/custom-gate3.png'
import custom4 from '../../../assets/images/custom-gate4.png'
import custom5 from '../../../assets/images/custom-gate5.png'
import custom6 from '../../../assets/images/custom-gate6.png'
import custom7 from '../../../assets/images/custom-gate7.png'
import custom8 from '../../../assets/images/custom-gate8.png'
import custom9 from '../../../assets/images/custom-gate9.png'
import custom10 from '../../../assets/images/custom-gate10.png'
import custom11 from '../../../assets/images/custom-gate11.png'

class customGatesPage extends React.Component {
  render() {
    const siteTitle =
      'Custom Gate Designs in Arlington, TX and the Dallas-Fort Worth, TX Area'
    const siteDescription =
      'Call  For a Custom Gate in Arlington, TX or the Dallas-Forth Worth, TX Area. We Build and Install Automatic Gates for Any Property'

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />

        <div className="container-fluid text-center headline gates-headline">
          <div className="container">
            <h1 className="text-white m-0">Custom Gate Designs</h1>
            <hr />
          </div>
        </div>
        <div className="container d-padding-t d-padding-b text-center bell-gates">
          <div className="row flex-column-reverse flex-md-row mb-5">
            <div className="col-md-12">
              <p className="mx-auto">
                Have a specific gate design in mind for your property? J&J Gates
                Service and Design can create a custom gate built to your
                specifications for a unique look for your home or business. Get
                your custom gate for a great price in Dallas-Fort Worth, TX.
                Just give us a call at{' '}
                <a href="tel:+1-817-466-2794">(817) 466-2794</a>. These are some
                of the custom projects we have created in the past.
                <strong>
                  {' '}
                  Click on any of the designs below for a pdf file you can print
                  or download.
                </strong>{' '}
              </p>
            </div>
          </div>
          <div className="row flex-column-reverse flex-md-row mb-5">
            <div className="col-md-4">
              <a
                title="anna rudnicki"
                href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085421/jjgates/custom-gates-pdf/anna-rudnicki-gates.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyLoadImage
                  className="w-100"
                  src={custom1}
                  alt="Custom Gate 1"
                />
              </a>
            </div>
            <div className="col-md-4">
              <a
                title="bruce"
                href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085420/jjgates/custom-gates-pdf/cory-bruce-gate.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyLoadImage
                  className="w-100"
                  src={custom2}
                  alt="Custom Gate 2"
                />
              </a>
            </div>
            <div className="col-md-4">
              <a
                title=""
                href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085420/jjgates/custom-gates-pdf/david-bell-gate.pdf"
              >
                <LazyLoadImage
                  className="w-100"
                  src={custom3}
                  alt="Custom Gate 3"
                />
              </a>
            </div>
          </div>
          <div className="row flex-column-reverse flex-md-row mb-5">
            <div className="col-md-4">
              <a
                href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085420/jjgates/custom-gates-pdf/holbrook-gate.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyLoadImage
                  className="w-100"
                  src={custom4}
                  alt="Custom Gate 4"
                />
              </a>
            </div>
            <div className="col-md-4">
              <a
                href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085420/jjgates/custom-gates-pdf/jj-knotts-gate.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyLoadImage
                  className="w-100"
                  src={custom5}
                  alt="Custom Gate 5"
                />
              </a>
            </div>
            <div className="col-md-4">
              <a
                href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085421/jjgates/custom-gates-pdf/wellington-gate.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyLoadImage
                  className="w-100"
                  src={custom6}
                  alt="Custom Gate 6"
                />
              </a>
            </div>
          </div>
          <div className="row flex-column-reverse flex-md-row mb-5">
            <div className="col-md-4">
              <a
                href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085421/jjgates/custom-gates-pdf/rakesh-gate.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyLoadImage
                  className="w-100"
                  src={custom7}
                  alt="Custom Gate 7"
                />
              </a>
            </div>
            <div className="col-md-4">
              <a
                href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085420/jjgates/custom-gates-pdf/patel-gate-with-letter.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyLoadImage
                  className="w-100"
                  src={custom8}
                  alt="Custom Gate 8"
                />
              </a>
            </div>
            <div className="col-md-4">
              <a
                href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577085421/jjgates/custom-gates-pdf/neal-polan-gate.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyLoadImage
                  className="w-100"
                  src={custom9}
                  alt="Custom Gate 9"
                />
              </a>
            </div>
          </div>
          <div className="row flex-column-reverse flex-md-row mb-5">
            <div className="col-md-4">
              <a
                href="https://res.cloudinary.com/dnla85pdq/image/upload/v1577091954/jjgates/custom-gates-pdf/Lisa-Brown-Gate.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                <LazyLoadImage
                  className="w-100"
                  src={custom10}
                  alt="Custom Gate 10"
                />
              </a>
            </div>
            <div className="col-md-4">
              <LazyLoadImage
                className="w-100"
                src={custom11}
                alt="Custom Gate 11"
              />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default customGatesPage

export const customGatesPageQuery = graphql`
  query customGatesPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
